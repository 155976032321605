import { combineReducers, configureStore } from '@reduxjs/toolkit';
import suggestionsReducer from '@common/pages/suggestions/suggestion.slice';
import themeReducer from '@common/theme/theme.slice';
import suggestionsFilterReducer from '@common/components/filters/filter.slice';

const rootReducer = combineReducers({
  suggestions: suggestionsReducer,
  theme: themeReducer,
  suggestionsFilter: suggestionsFilterReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
