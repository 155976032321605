import { lazy } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import Loadable from '@common/components/loadable';
import Login from '../common/pages/login/login';
import Signup from '../common/pages/login/signup';
import MultiAccount from '../common/pages/login/multi-account';
import { ProtectedRoute } from '../common/components/authentication/admin-protected';
import { PermittedRoute } from '../common/components/permissions/permittedRoute';

const SuggestionsList = Loadable(
  lazy(() => import('../administration/pages/administration/suggestions')),
);
const OrganizationsAdmin = Loadable(
  lazy(() => import('../administration/pages/administration/organizations')),
);
const UsersIndexAdmin = Loadable(
  lazy(() => import('../administration/pages/administration/organizations/users')),
);
const GroupsIndexAdmin = Loadable(
  lazy(() => import('../administration/pages/administration/organizations/groups')),
);
const PackagesAdmin = Loadable(
  lazy(() => import('../administration/pages/administration/packages')),
);
//import { Administration } from "../administration/pages/administration";
const FeaturesAdmin = Loadable(
  lazy(() => import('../administration/pages/administration/features')),
);
const SuggestionsWeights = Loadable(
  lazy(() => import('../administration/pages/administration/suggestions-weights/screens')),
);
const AudienceAdmin = Loadable(
  lazy(() => import('../administration/pages/administration/audiences')),
);
const CycleAdmin = Loadable(lazy(() => import('../administration/pages/administration/cycle')));
const CyclesPlansAdmin = Loadable(
  lazy(() => import('../administration/pages/administration/cycles-plans')),
);
const AdminCyclesPlansTargets = Loadable(
  lazy(() => import('../administration/pages/administration/cycles-plans-targets')),
);
const Home = Loadable(lazy(() => import('../adminHome')));

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login mode="web" callback={() => ({})} />,
  },
  {
    path: '/signup',
    element: <Signup mode="web" callback={() => ({})} />,
  },
  {
    path: '/my/accounts',
    element: <MultiAccount />,
  },
  {
    path: '/admin',
    element: (
      <ProtectedRoute>
        <PermittedRoute accessRules={{ roles: ['global_admin'] }}>
          <Home />
        </PermittedRoute>
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'packages',
        element: <PackagesAdmin />,
      },
      {
        path: 'features',
        element: <FeaturesAdmin />,
      },
      {
        path: 'organizations',
        element: <OrganizationsAdmin />,
      },
      {
        path: 'users',
        element: <UsersIndexAdmin />,
      },
      {
        path: 'groups',
        element: <GroupsIndexAdmin />,
      },
      {
        path: 'suggestions/suggestions-weights',
        element: <SuggestionsWeights />,
      },
      {
        path: 'suggestions/list',
        element: <SuggestionsList />,
      },
      {
        path: 'audiences',
        element: <AudienceAdmin />,
      },
      {
        path: 'veeva/cycle',
        element: <CycleAdmin />,
      },
      {
        path: 'veeva/cycles-plans',
        element: <CyclesPlansAdmin />,
      },
      {
        path: 'veeva/cycle-plans-targets',
        element: <AdminCyclesPlansTargets />,
      },
    ],
  },

  {
    path: '/',
    element: <Navigate to="/admin/packages" replace />,
  },
  //   {
  //     path: "/",
  //     element: (
  //       <ProtectedRoute>
  //         <Administration />
  //       </ProtectedRoute>
  //     ),
  //   },

  {
    path: '*',
    element: <Navigate to="/login" />,
  },
]);

export default router;
