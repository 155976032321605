import { app, authentication } from '@microsoft/teams-js';
import { instance } from '../../apis/baseApi';
import { config } from '../../config/configurations';
import { getCookie, isCookieSetAndValid } from '@common/helpers/cookies';

export const initializeMS = async () => {
  await app
    .initialize()
    .then(() => {
      app.notifySuccess();
    })
    .catch((error) => {
      app.notifyFailure(error);
    });
};

export const getSBToken = async (token: string) => {
  const base_url = `${config.REACT_APP_URL_BACKEND_API}/${config.REACT_APP_API_VERSION}/authentication/oauth2/grant`;
  return instance.post(base_url, {
    grant_type: 'sso_login',
    provider: 'microsoft',
    token: token,
    mode: 'teams',
  });
};

let refreshAttempts = 0;
const MAX_REFRESH_ATTEMPTS = 2;

export const refreshMS = async () => {
  if (refreshAttempts >= MAX_REFRESH_ATTEMPTS) {
    refreshAttempts = 0;
    throw new Error('Max refresh attempts reached');
  }

  refreshAttempts++;

  try {
    const isCookieValid = isCookieSetAndValid('ms-token');
    const token = isCookieValid
      ? getCookie('ms-token')
      : await authentication.getAuthToken({ silent: true });
    const response = await getSBToken(token);

    if (response.status === 200) {
      document.cookie = `x-access-token=${response.data.access_token};SameSite=None;Secure`;
      refreshAttempts = 0;
      return response;
    }
    throw new Error('Failed to get SB token');
  } catch (error) {
    throw error;
  }
};
