import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { useAuth } from '../../pages/login/login-context';
import { reinitializeInstance } from '../../apis/baseApi';
import { LoginLoader } from '@common/components/loader/login-loader';

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn, login, checkLogin } = useAuth();
  const searchParams = new URLSearchParams(window.location.search);
  const [checkPerformed, setCheckPerformed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn);

  const checkUser = async () => {
    try {
      const user = await checkLogin();
      if (user) {
        setIsAuthenticated(true);
        login(user);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setCheckPerformed(true);
    }
  };

  useEffect(() => {
    if (searchParams.get('x-access-token')) {
      const cookies = new Cookies();
      cookies.set('x-access-token', searchParams.get('x-access-token'), {
        path: '/',
        maxAge: 3600,
        sameSite: 'none',
        secure: true,
      });
      cookies.set('expiry', searchParams.get('expiry'), {
        path: '/',
        maxAge: 7200,
        sameSite: 'none',
        secure: true,
      });
      reinitializeInstance();
      window.location.href = '/';
    }
    checkUser();
  }, []);

  if (!checkPerformed) {
    return <LoginLoader />;
  } else if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};
